import React from 'react'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { useTableScroll } from './table'
import { Column } from 'react-table'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import PaymentMethod from '../payment-method'
import { CmsTenant } from '../../services/cms'
import { dateFormat } from '../../../../../shared/config/date'
import moment from 'moment'
import { PaymentIntent } from '@stripe/stripe-js'
import { PaymentData, CustomerPaymentMethod } from '../../../../../shared/types/cms'
import PaymentMethodPreview from './payment-method-preview'

export type PaymentStatus = PaymentIntent['status'] | 'scheduled'
export const PaymentStatusMessage: Record<PaymentStatus, string> = {
  scheduled: `scheduled`,
  succeeded: `succeeded`,
  canceled: `canceled`,
  processing: `processing`,
  requires_action: `action required`, // this should not appear if a payment method exists
  requires_capture: `processing`,
  requires_confirmation: `payment confirmation required`, // this should not appear if a payment method exists
  requires_payment_method: `payment method required`,
}

interface TableDataEntry {
  [key: string]: unknown
  date: string
  price: string
  status: PaymentStatus
  room?: {
    id: number
    name: string
  }
  invoice_url?: string
}

const siteMetaQuery = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`

export const PaymentInfo: React.FC = () => {
  const { site } = useStaticQuery(siteMetaQuery)
  const { t } = useTranslation()
  const { language, navigate } = useI18next()
  const { Table, TableScroll } = useTableScroll()
  const [paymentMethodVisible, setPaymentMethodVisible] = React.useState(false)
  const [paymentMethodRedirect, setPaymentMethodRedirect] = React.useState<string>()
  const [upcomingPayment, setUpcomingPayment] = React.useState<PaymentData>()
  const [paymentHistoryData, setPaymentHistoryData] = React.useState<PaymentData[]>([])
  const [hasMorePayments, setHasMorePayments] = React.useState(false)
  const [loadingPayments, setLoadingPayments] = React.useState(false)

  const tableColumns = React.useMemo<Column<Record<string, unknown>>[]>(
    () => [
      {
        Header: t(`Date`) ?? ``,
        accessor: `date`,
      },
      {
        Header: t(`Amount`) ?? ``,
        accessor: `price`,
        Cell: ({ value }: { value: string }) => <span className="account__payment-amount">{value}€</span>,
      },
      {
        Header: t(`Room`) ?? ``,
        id: `room_name`,
        accessor: (row: unknown) => {
          const { room } = row as TableDataEntry
          if (room === undefined) return null
          return room.name
        },
      },
      {
        Header: t(`Status`) ?? ``,
        accessor: `status`,
        Cell: ({ value }: { value: PaymentStatus }) => (
          <span className={`account__payment-status account__payment-status--${value}`}>
            {t(PaymentStatusMessage[value])}
          </span>
        ),
      },
      {
        Header: t(`PDF Invoice`) ?? ``,
        accessor: `invoice_url`,
        Cell: ({ value }: { value?: string }) => {
          if (!value) return null

          return (
            <a className="account__payment-invoice" href={value} target="blank">
              {t(`Invoice`)}
            </a>
          )
        },
      },
    ],
    [],
  )

  const getPaymentHistory = () => {
    if (loadingPayments) return
    setLoadingPayments(true)

    let startingAfter = undefined
    let getUpcoming = true
    if (hasMorePayments) {
      startingAfter = paymentHistoryData[paymentHistoryData.length - 1].id
      getUpcoming = false
    }

    CmsTenant.getPaymentHistory({ startingAfter, getUpcoming, limit: 10 })
      .then(({ payments, hasMore, upcomingPayment = undefined }) => {
        setUpcomingPayment(upcomingPayment)
        setPaymentHistoryData([...paymentHistoryData, ...payments])
        setHasMorePayments(hasMore)
        setLoadingPayments(false)
      })
      .catch((e: Error) => {
        console.log(e.message)
        setHasMorePayments(false)
        setLoadingPayments(false)
      })
  }

  const tableData = React.useMemo<TableDataEntry[]>(() => {
    const dataEntries: TableDataEntry[] = []
    if (!!upcomingPayment) {
      const upcomingPaymentEntry: TableDataEntry = {
        date: moment.unix(upcomingPayment.date).format(dateFormat),
        price: (upcomingPayment.amount / 100).toFixed(2),
        status: `scheduled`,
      }

      if (upcomingPayment.roomTypeId && upcomingPayment.roomTypeName) {
        upcomingPaymentEntry.room = {
          id: Number(upcomingPayment.roomTypeId),
          name:
            language !== `de` && upcomingPayment.roomTypeNameEn
              ? upcomingPayment.roomTypeNameEn
              : upcomingPayment.roomTypeName,
        }
      }

      dataEntries.push(upcomingPaymentEntry)
    }

    paymentHistoryData.forEach((payment) => {
      if (payment.status !== undefined) {
        let room = undefined
        if (payment.roomTypeId !== undefined && payment.roomTypeName !== undefined) {
          room = {
            id: Number(payment.roomTypeId),
            name: language !== `de` && payment.roomTypeNameEn ? payment.roomTypeNameEn : payment.roomTypeName,
          }
        }

        dataEntries.push({
          date: moment.unix(payment.date).format(dateFormat),
          price: (payment.amount / 100).toFixed(2),
          status: payment.status as PaymentStatus,
          room: room,
          invoice_url: payment.invoicePdf ? payment.invoicePdf : payment.receiptUrl,
        })
      }
    })

    return dataEntries
  }, [upcomingPayment, paymentHistoryData])

  React.useEffect(() => {
    getPaymentHistory()

    const urlQuery = new URLSearchParams(window.location.search)
    const urlAction = urlQuery.get(`do`)
    const redirectUrl = urlQuery.get(`redirect`)

    if (urlAction === `add-payment`) {
      setPaymentMethodVisible(true)
      if (redirectUrl) setPaymentMethodRedirect(redirectUrl)
    }

    CmsTenant.getPaymentMethods()
      .then((methods) => {
        setPaymentMethods(methods)
      })
      .catch((e: Error) => {
        console.log(e.message)
      })
  }, [])
  const [paymentMethods, setPaymentMethods] = React.useState<CustomerPaymentMethod[] | null>(null)

  return (
    <>
      <Helmet title={t(`Payment info`)} titleTemplate={site.siteMetadata.titleTemplate} />

      <div className="account__intro">
        <h2 className="account__title ">{t(`Payment info`)}</h2>
        <div className="account__text">{t(`Update or reset the payment info below`)}</div>
      </div>

      <div className="account__subtitle">{t(`Payment method`)}</div>
      <div>
        {paymentMethods && paymentMethods.map((method) => <PaymentMethodPreview method={method} key={method.id} />)}
      </div>

      <div className="account__payment-change cursor-pointer" onClick={() => setPaymentMethodVisible(true)}>
        {t(`Change method`)}
      </div>
      <div>
        <PaymentMethod
          visible={paymentMethodVisible}
          onClose={(reason) => {
            if (reason === `success` && paymentMethodRedirect) {
              navigate(paymentMethodRedirect)
            } else {
              setPaymentMethodVisible(false)
            }
          }}
          onMethodAdded={() => {
            if (!paymentMethodRedirect) {
              CmsTenant.getPaymentMethods()
                .then(setPaymentMethods)
                .catch((e: Error) => {
                  console.log(e.message)
                })
            }
          }}
        />
      </div>

      <div className="account__subtitle d-flex d-lg-block justify-content-between align-items-center">
        {t(`Payment history`)}
        <TableScroll className="d-lg-none" />
      </div>
      <div>
        <Table columns={tableColumns} data={tableData} />
        {(hasMorePayments || loadingPayments) && (
          <button className="account__payment-more-button" onClick={() => getPaymentHistory()}>
            {loadingPayments ? t(`Loading`) : t(`Load more`)}...
          </button>
        )}
      </div>
    </>
  )
}

export default PaymentInfo
