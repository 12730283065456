import { graphql, PageProps, navigate } from 'gatsby'
import * as React from 'react'
import MainLayout from '../components/main-layout'
import '../styles/main.scss'
import MobileHeader from '../components/mobile-header'
import Footer from '../components/footer'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import PageWrapper from '../components/page/page-wrapper'
import AuthenticatedPage from '../components/page/authenticated-page'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useLocation } from '@reach/router'
import Profile from '../components/account/profile'
import PaymentInfo from '../components/account/payment-info'
import Contracts from '../components/account/contracts'
import Header from '../components/header/header'

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
const accountTabs = [`#profile`, `#payment`, `#history`]

// Wrap the page to access the authcontext
export const AccountPage: React.FC<PageProps> = (props) => {
  return (
    <PageWrapper>
      <AuthenticatedPage>
        <AccountPageInner {...props} />
      </AuthenticatedPage>
    </PageWrapper>
  )
}

export const AccountPageInner: React.FC<PageProps> = ({}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const [tabIndex, setTabIndex] = React.useState<number>(0)
  const [tabInitialized, setTabInitialized] = React.useState(false)

  React.useEffect(() => {
    // Read tab from url
    const urlHash = typeof window === `undefined` ? `` : location.hash
    const newTabIndex = accountTabs.indexOf(urlHash)
    if (newTabIndex !== -1) {
      setTabIndex(newTabIndex)
    } else {
      setTabIndex(0)
    }
    setTabInitialized(true)
  }, [location])

  return (
    <MainLayout className="account-page">
      <Header />

      <div className="horizontal-separator d-lg-none" />
      <div className={`account`}>
        <div className={`container-lg`}>
          <div className="account__main-row row">
            <div className="account__wrapper col-md">
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => {
                  navigate(location.pathname + accountTabs[index])
                }}
              >
                <TabList>
                  <Tab>{t(`Profile`)}</Tab>
                  <Tab>{t(`Payment info`)}</Tab>
                  <Tab>{t(`Contracts`)}</Tab>
                </TabList>
                <TabPanel className="react-tabs__tab-panel account__profile">{tabInitialized && <Profile />}</TabPanel>
                <TabPanel className="react-tabs__tab-panel account__payment">
                  <PaymentInfo />
                </TabPanel>
                <TabPanel className="react-tabs__tab-panel account__contracts">
                  <Contracts />
                </TabPanel>
              </Tabs>
            </div>

            <div className="col-md-auto d-none d-lg-block"></div>
          </div>
        </div>
      </div>

      <Footer />
    </MainLayout>
  )
}
export default AccountPage
