import React from 'react'
import crossIcon from '../images/icon-cross.svg'
import bankIcon from '../images/payment/icon-bank.svg'
import cardIcon from '../images/payment/icon-card.svg'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Modal from './modal'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { graphql, useStaticQuery } from 'gatsby'
import { Elements, useStripe, CardElement, useElements, IbanElement } from '@stripe/react-stripe-js'
import { PaymentMethodType } from '../services/cms/tenant'
import { CmsTenant } from '../services/cms'
import { useValidEmail } from '../hooks/auth/useValidEmail'
import Alert, { AlertVariant } from './alert'
import LoadingButton from './loading-button'

interface PaymentMethodProps {
  visible: boolean
  onClose: (reason?: string) => void
  onMethodAdded: () => void
}

const siteMetaQuery = graphql`
  query {
    site {
      siteMetadata {
        stripePublishableKey
      }
    }
  }
`

const PaymentMethodInner: React.FC<PaymentMethodProps> = ({ visible, onClose, onMethodAdded }) => {
  const stripe = useStripe()
  const elements = useElements()
  const { t } = useTranslation()
  const [methodType, setMethodType] = React.useState<PaymentMethodType>(PaymentMethodType.SEPA)
  const [name, setName] = React.useState<string>(``)
  const { email, setEmail, emailIsValid } = useValidEmail(``)
  const [errorMessage, setErrorMessage] = React.useState(``)
  const [success, setSuccess] = React.useState(false)
  const [isBusy, setIsBusy] = React.useState(false)
  const [isIbanValid, setIsIbanValid] = React.useState(false)
  const [isCardValid, setIsCardValid] = React.useState(false)
  const [disclaimerAgreed, setDisclaimerAgreed] = React.useState(false)

  const sepaIsValid = !!stripe && emailIsValid && email !== `` && name !== `` && isIbanValid
  const currentIsValid =
    !!stripe &&
    disclaimerAgreed &&
    ((methodType === PaymentMethodType.SEPA && sepaIsValid) || (methodType === PaymentMethodType.card && isCardValid))

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    if (success) {
      onClose(`success`)
      return
    }

    if (!stripe || isBusy) return

    if (!currentIsValid) {
      if (!disclaimerAgreed) {
        setErrorMessage(t(`add_method_authorization_needed`))
      } else {
        setErrorMessage(t(`The form is missing information`))
      }
      return
    }

    if (methodType === PaymentMethodType.SEPA) {
      setIsBusy(true)
      handleSubmitSEPA()
        .catch((error) => setErrorMessage(t(error?.message || `Something went wrong`)))
        .finally(() => setIsBusy(false))
    } else if (methodType === PaymentMethodType.card) {
      setIsBusy(true)
      handleSubmitCard()
        .catch((error) => setErrorMessage(t(error?.message || `Something went wrong`)))
        .finally(() => setIsBusy(false))
    }
  }

  const handleSubmitSEPA = async () => {
    if (!stripe || !elements) return

    const element = elements.getElement(IbanElement)
    if (element !== null) {
      const createIntentResult = await CmsTenant.createSetupIntent()

      if (`secret` in createIntentResult) {
        const confirmIntentResult = await stripe.confirmSepaDebitSetup(createIntentResult.secret, {
          payment_method: {
            sepa_debit: element,
            billing_details: {
              name: name,
              email: email,
            },
          },
        })

        if (confirmIntentResult.setupIntent?.status === `succeeded`) {
          setSuccess(true)
          onMethodAdded()
        } else {
          throw new Error(confirmIntentResult?.error?.message || `Something went wrong`)
        }
      } else {
        throw new Error(`Something went wrong during setup intent creation`)
      }
    }
  }

  const handleSubmitCard = async () => {
    if (!stripe || !elements) return

    const cardElement = elements.getElement(CardElement)
    if (cardElement !== null) {
      const createIntentResult = await CmsTenant.createSetupIntent()

      if (`secret` in createIntentResult) {
        const confirmIntentResult = await stripe.confirmCardSetup(createIntentResult.secret, {
          payment_method: {
            card: cardElement,
          },
        })

        if (confirmIntentResult.setupIntent?.status === `succeeded`) {
          setSuccess(true)
          onMethodAdded()
        } else {
          throw new Error(confirmIntentResult?.error?.message || `Something went wrong`)
        }
      } else {
        throw new Error(`Something went wrong during setup intent creation`)
      }
    }
  }

  return (
    <form className="w-100" onSubmit={handleSubmit}>
      <div className="payment-method">
        <div className="d-flex justify-content-between flex-grow-1">
          <div className="payment-method__title">{success ? `` : t(`Add payment method`)}</div>
          <div className="payment-method__close cursor-pointer" onClick={() => onClose()}>
            <img src={crossIcon} />
          </div>
        </div>
        {success ? (
          <>
            <div className="d-flex flex-column align-items-center">
              <i className="auth-modal__success-icon icomoon icomoon-checkmark" />
              <div className="auth-modal__title mt-4">{t(`Success!`)}</div>
              <div className="auth-modal__hint mt-2 mb-4">{t(`New payment method saved`)}</div>
            </div>
          </>
        ) : (
          <>
            <div className="payment-method__methods">
              <div
                className={`payment-method__methods-button ${
                  methodType === PaymentMethodType.SEPA ? `payment-method__methods-button--selected` : ``
                }`}
                onClick={() => setMethodType(PaymentMethodType.SEPA)}
              >
                <img src={bankIcon} /> {t(`SEPA Direct Debit`)}
              </div>
              <div
                className={`payment-method__methods-button ${
                  methodType === PaymentMethodType.card ? `payment-method__methods-button--selected` : ``
                }`}
                onClick={() => setMethodType(PaymentMethodType.card)}
              >
                <img src={cardIcon} /> {t(`Card`)}
              </div>
            </div>
            {methodType === PaymentMethodType.SEPA && (
              <>
                <div className="form-group form-floating">
                  <input
                    className="form-control"
                    id="stripe-sepa-name"
                    placeholder={t(`Name`)}
                    onChange={(event) => setName(event.target.value)}
                  />
                  <label htmlFor="stripe-sepa-name">{t(`Name`)}</label>
                </div>
                <div className="form-group form-floating">
                  <input
                    className="form-control"
                    id="stripe-sepa-email"
                    placeholder={t(`Email`)}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <label htmlFor="stripe-sepa-email">{t(`Email`)}</label>
                </div>
                <IbanElement
                  className="form-control"
                  options={{
                    supportedCountries: [`SEPA`],
                    // Elements can use a placeholder as an example IBAN that reflects
                    // the IBAN format of your customer's country. If you know your
                    // customer's country, we recommend that you pass it to the Element as the
                    // placeholderCountry.
                    placeholderCountry: `DE`,
                  }}
                  onChange={(element) => {
                    setIsIbanValid(element.complete && element.error === undefined)
                  }}
                />
              </>
            )}

            {methodType === PaymentMethodType.card && (
              <>
                <CardElement
                  className="form-control"
                  options={{ hidePostalCode: true }}
                  onChange={(element) => {
                    setIsCardValid(element.complete && element.error === undefined)
                  }}
                />
              </>
            )}

            <div className="payment-method__disclaimer">
              <div className="payment-method__disclaimer-checkbox">
                <input
                  id="payment-disclaimer-agree"
                  type="checkbox"
                  checked={disclaimerAgreed}
                  onChange={(event) => setDisclaimerAgreed(event.target.checked)}
                />
              </div>
              <div>
                <label htmlFor="payment-disclaimer-agree">{t(`stripe_disclaimer`)}</label>
              </div>
            </div>
            {errorMessage && <Alert variant={AlertVariant.Error}>{errorMessage}</Alert>}
          </>
        )}

        <LoadingButton
          className={`payment-method__submit ${
            success || (!!stripe && currentIsValid && !isBusy) ? `` : `payment-method__submit--disabled`
          }`}
          type="submit"
          loading={!success && (isBusy || !stripe || !elements)}
        >
          {(success ? t(`Okay`) : t(`Save payment method`)) as string}
        </LoadingButton>
      </div>
    </form>
  )
}

export const PaymentMethod: React.FC<PaymentMethodProps> = (props) => {
  const { visible, onClose } = props
  const { site } = useStaticQuery(siteMetaQuery)
  const [stripeController, setStripeController] = React.useState<Stripe | null>(null)

  React.useEffect(() => {
    loadStripe(site.siteMetadata.stripePublishableKey).then((stripe) => setStripeController(stripe))
  }, [])

  return (
    <Modal visible={visible} onClose={onClose} placement="bottom">
      <Elements stripe={stripeController}>
        <PaymentMethodInner {...props} />
      </Elements>
    </Modal>
  )
}
export default PaymentMethod
