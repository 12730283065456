import React from 'react'
import { Column, useTable } from 'react-table'
import ChevronRight from '../../images/chevron-right-round.svg'
import ChevronLeft from '../../images/chevron-left-round.svg'

interface TableProps {
  columns: Column<Record<string, unknown>>[]
  data: Array<Record<string, unknown>>
  containerRef?: React.MutableRefObject<HTMLDivElement | null>
  className?: string
}
export const Table: React.FC<TableProps> = ({ columns, data, containerRef, className = `` }) => {
  const tableInstance = useTable({ columns, data })
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

  return (
    <div ref={containerRef} className={`account-table__wrapper ` + className}>
      <table {...getTableProps({ className: `account-table` })}>
        <thead className="account-table__header">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps({ className: `account-table__header-row` })}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps({ className: `account-table__header-cell` })}>
                  {column.render(`Header`)}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps({ className: `account-table__body` })}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps({ className: `account-table__row` })}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps({ className: `account-table__cell` })}>{cell.render(`Cell`)}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export const TableScroll: React.FC<{ scrollRight: () => void; scrollLeft: () => void; className?: string }> = ({
  scrollLeft,
  scrollRight,
  className = ``,
}) => {
  return (
    <div className={`account-tablescroll ` + className}>
      <img src={ChevronLeft} onClick={scrollLeft} />
      <div className="vertical-separator" />
      <img src={ChevronRight} onClick={scrollRight} />
    </div>
  )
}

export const useTableScroll: () => { Table: React.FC<TableProps>; TableScroll: React.FC<{ className?: string }> } =
  () => {
    const tableContainer = React.useRef<HTMLDivElement>(null)

    const scrollLeft = () => {
      if (tableContainer.current !== null) {
        tableContainer.current.scrollBy(-tableContainer.current.clientWidth, 0)
      }
    }

    const scrollRight = () => {
      if (tableContainer.current !== null) {
        tableContainer.current.scrollBy(tableContainer.current.clientWidth, 0)
      }
    }

    return {
      Table: (props: TableProps) => <Table containerRef={tableContainer} {...props} />,
      TableScroll: ({ className = `` }) => (
        <TableScroll scrollLeft={scrollLeft} scrollRight={scrollRight} className={className} />
      ),
    }
  }

export default Table
