import * as React from 'react'
import { CustomerPaymentMethod } from '../../../../../shared/types/cms'
import bankIcon from '../../images/payment/icon-bank.svg'
import visaIcon from '../../images/payment/icon-visa.svg'
import mastercardIcon from '../../images/payment/icon-mastercard.svg'
import amexIcon from '../../images/payment/icon-amex.svg'
import discoverIcon from '../../images/payment/icon-discover.svg'
import unionpayIcon from '../../images/payment/icon-unionpay.svg'
import jcbIcon from '../../images/payment/icon-jcb.svg'
import dinersclubIcon from '../../images/payment/icon-dinersclub.svg'
import cardIcon from '../../images/payment/icon-card.svg'

export const getPaymentMethodIcon = (type: CustomerPaymentMethod['type'], brand = ``): string => {
  if (type === `sepa_debit`) {
    return bankIcon
  } else {
    switch (brand) {
      case `visa`:
        return visaIcon
      case `mastercard`:
        return mastercardIcon
      case `american_express`:
        return amexIcon
      case `discover`:
        return discoverIcon
      case `union_pay`:
        return unionpayIcon
      case `jcb`:
        return jcbIcon
      case `dinersclub`:
        return dinersclubIcon
      default:
        return cardIcon
    }
  }
}

export const PaymentMethodPreview: React.FC<{ method: CustomerPaymentMethod }> = ({ method }) => {
  switch (method.type) {
    case `card`:
      return (
        <div className="account__payment-method">
          <div className="account__payment-method-icon">
            <img src={getPaymentMethodIcon(method.type, method.data.brand)} />
          </div>
          <div className="account__payment-method-number">{`**** **** **** ${method.data.last4}`}</div>
          <div className="account__payment-method-expiry">{`${method.data.expMonth < 10 ? `0` : ``}${
            method.data.expMonth
          }/${method.data.expYear}`}</div>
        </div>
      )
    case `sepa_debit`:
      return (
        <div className="account__payment-method">
          <div className="account__payment-method-icon">
            <img src={getPaymentMethodIcon(method.type)} />
          </div>
          <div className="account__payment-method-info">{`${method.data.country}** **** ${method.data.last4}`}</div>
        </div>
      )
  }

  return null
}

export default PaymentMethodPreview
