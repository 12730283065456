import React from 'react'
import { FormFieldSocial, noSocialPlatformId } from './form-field-social'
import { FormField } from './form-field'
import DocumentsUploader, { FileWithPreview } from '../documents-uploader'
import { AuthContext } from '../../contexts/authContext'
import { useValidSocialNetworks } from '../../hooks/useValidSocialNetworks'
import { CmsTenant } from '../../services/cms'
import { Alert, AlertVariant } from '../alert'
import { validateFormEvent } from '../../pages/application'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Image, Tenant } from '../../../../../shared/types/cms'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import PhoneInput from '../phone-input'
import useValidPhone from '../../hooks/useValidPhone'
import LoadingButton from '../loading-button'
import useValidBirthDate from '../../hooks/useValidBirthDate'
import DateInput from '../date-input'
import FilePictureIcon from '../../images/icon-file-picture.svg'

const siteMetaQuery = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`

export const Profile: React.FC = () => {
  const { site } = useStaticQuery(siteMetaQuery)
  const { t } = useTranslation()
  const authContext = React.useContext(AuthContext)
  const [isLoading, setIsLoading] = React.useState(true)

  const [firstName, setFirstName] = React.useState(``)
  const [lastName, setLastName] = React.useState(``)
  const [email, setEmail] = React.useState(``)
  const [emailVerified, setEmailVerified] = React.useState(false)
  const { birthDate, setBirthDate, birthDateIsValid } = useValidBirthDate()
  const { phone, setPhone, phoneIsValid } = useValidPhone(``)
  const [identificationNumber, setIdentificationNumber] = React.useState(``)
  const [identificationIssuer, setIdentificationIssuer] = React.useState(``)
  const [street, setStreet] = React.useState(``)
  const [zipCode, setZipCode] = React.useState(``)
  const [city, setCity] = React.useState(``)
  const [country, setCountry] = React.useState(``)
  const [studentStudyLocation, setStudentStudyLocation] = React.useState(``)
  const [studentParentsAddress, setStudentParentsAddress] = React.useState(``)
  const [studentProofOfIncomeType, setStudentProofOfIncomeType] = React.useState(``)
  const { socialNetworks, setSocialNetworks, socialNetworksIsValid } = useValidSocialNetworks([
    { platform: noSocialPlatformId, username: `` },
  ])
  const [documents, setDocuments] = React.useState<Array<FileWithPreview>>([])
  const [studentCertificates, setStudentCertificates] = React.useState<Array<FileWithPreview>>([])
  const [studentProofOfIncomeDocuments, setStudentProofOfIncomeDocuments] = React.useState<Array<FileWithPreview>>([])
  const [errorMessage, setErrorMessage] = React.useState(``)
  const [infoMessage, setInfoMessage] = React.useState(``)
  const setPhotos = (photos: Image[], setState: (_: File[]) => void) => {
    // Populate document field with current images
    Promise.all(
      photos.map(async (photo) => {
        const response = await fetch(photo.url)
        const blob = await response.blob()
        const file = new File([blob], `${photo.name}`, { type: photo.mime }) as FileWithPreview
        if (photo?.formats?.thumbnail) {
          file.preview = photo.formats.thumbnail.url
        } else if (photo.mime === `application/pdf`) {
          file.preview = FilePictureIcon
        }
        file.id = photo.id
        return file
      }),
    ).then((files) => setState(files))
  }

  React.useEffect(() => {
    setIsLoading(true)

    // Load email from authContext
    const emailAttr = authContext.attrInfo.find((attr) => attr.Name === `email`)
    setEmail(emailAttr?.Value ?? ``)

    const emailVerifiedAttr = authContext.attrInfo.find((attr) => attr.Name === `email_verified`)
    setEmailVerified(emailVerifiedAttr?.Value === `true`)

    setIsLoading(false)
  }, [authContext.authStatus])

  React.useEffect(() => {
    setIsLoading(true)

    if (authContext.tenant === null) {
      authContext.loadTenant()
    } else {
      setFirstName(authContext.tenant.name ?? ``)
      setLastName(authContext.tenant.surname ?? ``)
      setIdentificationNumber(authContext.tenant.passportNumber ?? ``)
      setIdentificationIssuer(authContext.tenant.passportIssued ?? ``)
      setPhone(authContext.tenant.phone ?? ``)
      setBirthDate(authContext.tenant.birthDate ?? undefined)
      setStreet(authContext.tenant.address?.street ?? ``)
      setZipCode(authContext.tenant.address?.zipCode ?? ``)
      setCity(authContext.tenant.address?.city ?? ``)
      setCountry(authContext.tenant.address?.country ?? ``)
      setStudentStudyLocation(authContext.tenant.studentStudyLocation ?? ``)
      setStudentParentsAddress(authContext.tenant?.studentParentsAddress ?? ``)
      setStudentProofOfIncomeType(authContext.tenant?.studentProofOfIncomeType ?? ``)

      if (authContext.tenant.socialNetworks.length > 0) setSocialNetworks(authContext.tenant.socialNetworks)
      else setSocialNetworks([{ platform: noSocialPlatformId, username: `` }])

      setPhotos(authContext.tenant.passportPhotos, setDocuments)
      if (authContext.tenant.studentCertificate)
        setPhotos(authContext.tenant.studentCertificate, setStudentCertificates)
      if (authContext.tenant.studentProofOfIncomeDocument)
        setPhotos(authContext.tenant.studentProofOfIncomeDocument, setStudentProofOfIncomeDocuments)

      setIsLoading(false)
    }
  }, [authContext.tenant])

  const isValid = React.useMemo(() => {
    return (
      firstName.length !== 0 &&
      lastName.length !== 0 &&
      phoneIsValid &&
      birthDateIsValid &&
      documents.length !== 0 &&
      identificationNumber.length !== 0 &&
      identificationIssuer.length !== 0 &&
      street.length !== 0 &&
      zipCode.length !== 0 &&
      city.length !== 0 &&
      country.length !== 0 &&
      socialNetworksIsValid
    )
  }, [
    firstName,
    lastName,
    birthDateIsValid,
    phoneIsValid,
    identificationNumber,
    identificationIssuer,
    street,
    zipCode,
    city,
    country,
    documents,
    socialNetworksIsValid,
  ])

  const handleSubmit: React.FormEventHandler = async () => {
    if (!isValid && window !== undefined) {
      window.dispatchEvent(new CustomEvent(validateFormEvent))
      setErrorMessage(t(`Please complete the form above`))
      return
    }

    if (isLoading) return
    setIsLoading(true)

    try {
      const response = (await CmsTenant.updateMe({
        name: firstName,
        surname: lastName,
        phone: phone,
        birthDate: birthDate,
        passportNumber: identificationNumber,
        passportIssued: identificationIssuer,
        passportPhotos: documents,
        studentCertificate: studentCertificates,
        studentStudyLocation: studentStudyLocation,
        studentParentsAddress: studentParentsAddress,
        studentProofOfIncomeType: [`BAFöG`, `Blocked account`, `Job`, `Other`].includes(studentProofOfIncomeType)
          ? studentProofOfIncomeType
          : null,
        studentProofOfIncomeDocument: studentProofOfIncomeDocuments,
        address: {
          street,
          zipCode,
          city,
          country,
        },
        socialNetworks: socialNetworks.filter((item) => item.platform !== noSocialPlatformId),
      })) as Tenant

      setPhotos(response.passportPhotos, setDocuments)
      if (response.studentCertificate) setPhotos(response.studentCertificate, setStudentCertificates)
      if (response.studentProofOfIncomeDocument)
        setPhotos(response.studentProofOfIncomeDocument, setStudentProofOfIncomeDocuments)

      setErrorMessage(``)
      setInfoMessage(t(`The profile has been updated`))
    } catch (e) {
      setErrorMessage(t(`Something went wrong`))
    }
    setIsLoading(false)
  }

  return (
    <>
      <Helmet title={t(`Profile`)} titleTemplate={site.siteMetadata.titleTemplate} />

      <div className="account__intro">
        <h2 className="account__title ">{t(`Profile`)}</h2>
        <div className="account__text">{t(`profile_instructions`)}</div>
      </div>

      <div className="account__subtitle">{t(`Profile info`)}</div>
      <form onSubmit={handleSubmit}>
        <div className="account__form row">
          <FormField
            id="first-name-input"
            label={t(`First name`)}
            onChange={setFirstName}
            value={firstName}
            valid={firstName.length > 0}
          />
          <FormField
            id="last-name-input"
            label={t(`Last name`)}
            onChange={setLastName}
            value={lastName}
            valid={lastName.length > 0}
          />
        </div>
      </form>

      <div className="account__subtitle">{t(`Password`)}</div>
      <div className="account__text">{t(`Update or reset the password below`)}</div>
      <div className="account__password d-flex flex-column flex-md-row align-items-start align-items-md-center">
        <button
          className="account__password-change"
          onClick={() => {
            authContext.setAuthFlowState(`changePassword`)
            authContext.setAuthFlowVisible(true)
          }}
        >
          {t(`Change password`)}
        </button>
        <div
          className="account__password-forgot cursor-pointer"
          onClick={async () => {
            await authContext.sendCode(email)
            authContext.setAuthFlowVisible(true)
            authContext.setAuthFlowState(`forgotPassword`)
          }}
        >
          {t(`I forgot my password`)}
        </div>
      </div>

      <div className="account__subtitle">{t(`Personal info`)}</div>
      <form onSubmit={handleSubmit}>
        <div className="account__form row">
          <FormField
            id="email-input"
            label={t(`Email`)}
            onChange={() => null} // Email can't be modified for the moment
            valid={email.length > 0}
            value={email}
            variant={`verified`}
            verified={emailVerified}
          />
          <DateInput label={t(`Date of birth`)} value={birthDate} valid={birthDateIsValid} onChange={setBirthDate} />
          <PhoneInput value={phone} onChange={setPhone} valid={phoneIsValid} />
          <FormField
            id="identification-number-input"
            label={t(`Passport number`)}
            onChange={setIdentificationNumber}
            value={identificationNumber}
            valid={identificationNumber.length > 0}
          />
          <FormField
            id="identification-issuer-input"
            label={t(`Place of issue`)}
            onChange={setIdentificationIssuer}
            value={identificationIssuer}
            valid={identificationIssuer.length > 0}
          />
          <div className="account__form-documents col-12 col-md-6">
            <DocumentsUploader
              label={t(`Passport photo(s)`)}
              onChange={setDocuments}
              documents={documents}
              valid={documents.length > 0}
            />
          </div>
          <div className="account__subtitle col-12">{t(`Address`)}</div>
          <FormField
            id="address-street-input"
            className="col-md-12"
            label={t(`Address`)}
            onChange={setStreet}
            value={street}
            valid={street.length > 0}
          />
          <FormField
            id="address-zipcode-input"
            className="col-lg-2"
            label={t(`Zip Code`)}
            onChange={setZipCode}
            value={zipCode}
            valid={zipCode.length > 0}
          />
          <FormField
            id="address-city-input"
            className="col-lg-5"
            label={t(`City`)}
            onChange={setCity}
            value={city}
            valid={city.length > 0}
          />
          <FormField
            id="country-city-input"
            className="col-lg-5"
            label={t(`Country`)}
            onChange={setCountry}
            value={country}
            valid={country.length > 0}
          />
          <div className="account__subtitle col-12">
            {t(`Student information`)}
            <span className="account__subtitle-note ps-2">{t(`Mandatory depending on location`)}</span>
          </div>
          <FormField
            id="student-study-location-input"
            className="col-md-12"
            label={t(`Study location`)}
            onChange={setStudentStudyLocation}
            value={studentStudyLocation}
          />
          <div className="application__form-documents col-12 col-md-6">
            <DocumentsUploader
              label={t(`Student certificate`)}
              onChange={setStudentCertificates}
              documents={studentCertificates}
            />
          </div>
          <FormField
            id="student-parents-address-input"
            className="col-md-12"
            label={t(`Parents address`)}
            onChange={setStudentParentsAddress}
            value={studentParentsAddress}
          />
          <FormField
            variant="select"
            id="student-poi-type-input"
            className="col-md-12"
            onChange={setStudentProofOfIncomeType}
            value={studentProofOfIncomeType}
            label={t(`Proof of income type`)}
          >
            <option value="">{t(`Choose`)}...</option>
            <option value="BAFöG">{t(`BAFöG`)}</option>
            <option value="Blocked account">{t(`Blocked account`)}</option>
            <option value="Job">{t(`Job`)}</option>
            <option value="Other">{t(`Other`)}</option>
          </FormField>
          <div className="application__form-documents col-12 col-md-6">
            <DocumentsUploader
              label={t(`Proof of income`)}
              onChange={setStudentProofOfIncomeDocuments}
              documents={studentProofOfIncomeDocuments}
            />
          </div>
          <div className="account__subtitle col-12">{t(`Social networks`)}</div>
          {socialNetworks.map((_, index) => {
            return (
              <FormFieldSocial
                key={`socialNetwork${index}`}
                id={`social-network-${index}-input`}
                value={_}
                onChange={(value) => {
                  const copyArray = [...socialNetworks]
                  copyArray[index] = value
                  setSocialNetworks(copyArray)
                }}
              />
            )
          })}
          <div
            className="account__form-add cursor-pointer col-6 col-md-4"
            onClick={() => {
              setSocialNetworks([...socialNetworks, { platform: noSocialPlatformId, username: `` }])
            }}
          >
            + {t(`Add more`)}
          </div>
        </div>
      </form>

      {errorMessage.length > 0 && <Alert variant={AlertVariant.Error}>{errorMessage}</Alert>}
      {infoMessage.length > 0 && <Alert>{infoMessage}</Alert>}
      <LoadingButton
        className={`account__send-button ${isValid ? `` : `account__send-button--disabled`}`}
        onClick={handleSubmit}
        loading={isLoading}
      >
        {t(`Update profile`)}
      </LoadingButton>
      <div className="horizontal-separator" />
      <div className="account__delete">
        <div className="account__delete-title">{t(`Delete Account`)}</div>
        <div className="account__text account__delete-text">{t(`delete_account_effects`)}:</div>
        <ul>
          <li className="account__text">{t(`delete_account_effects_1`)}</li>
          <li className="account__text">{t(`delete_account_effects_2`)}</li>
        </ul>
        <button
          className="account__delete-button"
          onClick={() => {
            authContext.setAuthFlowVisible(true)
            authContext.setAuthFlowState(`deleteAccount`)
          }}
        >
          {t(`Delete account`)}
        </button>
      </div>
    </>
  )
}

export default Profile
