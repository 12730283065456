import React from 'react'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { Contract } from '../../../../../shared/types/cms'
import moment from 'moment'
import chevronRight from '../../images/chevron-right-round.svg'
import { CmsRoomTypes, CmsTenant } from '../../services/cms'
import { dateFormat } from '../../../../../shared/config/date'
import PaymentMethodPreview from './payment-method-preview'
import { ContractConditionType } from '../../../../../shared/types/ContractConditionType'

export enum ContractStatus {
  Current = `current`,
  Ended = `ended`,
  Unsigned = `unsigned`,
}

const siteMetaQuery = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`

export const Contracts: React.FC = () => {
  const { site } = useStaticQuery(siteMetaQuery)
  const { t } = useTranslation()
  const { language } = useI18next()

  const [contractsData, setContractsData] = React.useState<Array<Contract & { monthlyPrice?: number }>>([])

  React.useEffect(() => {
    CmsTenant.getContracts()
      .then((contracts) => {
        const formattedContracts = contracts.map((contract: Contract & { monthlyPrice?: number }) => {
          const coldRentCondition = contract.conditions.find((c) => c.type === ContractConditionType.ColdRent)
          const utilitiesCondition = contract.conditions.find((c) => c.type === ContractConditionType.Utilities)
          if (coldRentCondition && utilitiesCondition) {
            contract.monthlyPrice = Number(coldRentCondition.value) + Number(utilitiesCondition.value)
          }
          return contract
        })
        setContractsData(formattedContracts)
      })
      .catch((e: Error) => {
        console.log(e.message)
      })
  }, [])

  return (
    <>
      <Helmet title={t(`Contracts`)} titleTemplate={site.siteMetadata.titleTemplate} />
      <div className="account__intro">
        <h2 className="account__title ">{t(`Contracts history`)}</h2>
        <div className="account__text">{t(`Check your contracts history info below`)}</div>
      </div>

      {contractsData.map((contract, index) => {
        const startMoment = moment(contract.startDate, `YYYY-MM-DD`)
        const endMoment = moment(contract.endDate, `YYYY-MM-DD`)
        let status = ContractStatus.Unsigned
        if (!!contract.contractSigned) {
          status = endMoment.isBefore() ? ContractStatus.Ended : ContractStatus.Current
        }

        const showOthers = !!contract.comment

        return (
          <div key={`contract_` + index} className="contract">
            <div className="contract__header row">
              <div className="col-auto d-flex">
                <img className="contract__header-image" src={contract.roomType.images[0]?.url} />
                <div className="d-flex flex-column">
                  <div className={`contract__header-status contract__header-status--${status}`}>{t(status)}</div>
                  <div className="contract__header-name">
                    {t(`Room`)} {CmsRoomTypes.getLocalName(contract.roomType, language)}
                  </div>
                  <div className="contract__header-dates">
                    {startMoment.format(dateFormat)}
                    {status === ContractStatus.Ended ? ` - ${endMoment.format(dateFormat)}` : ``}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-auto">
                {contract?.contractSigned?.url && (
                  <a href={contract.contractSigned.url} target="_blank">
                    <button className="contract__header-download w-100">
                      {t(`Download full contract`)} <img src={chevronRight} />
                    </button>
                  </a>
                )}
              </div>
            </div>
            <div className="horizontal-separator d-lg-none" />
            <div className="contract__section">
              <div className="contract__section-title">{t(`Tenant`)}</div>
              <div className="row">
                <div className="contract__section-detail">
                  <div className="contract__section-detail-name">{t(`Tenant`)}</div>
                  <div className="contract__section-detail-value">
                    {contract.tenantName} {contract.tenantSurname}
                  </div>
                </div>
                <div className="contract__section-detail">
                  <div className="contract__section-detail-name">{t(`Passport number`)}</div>
                  <div className="contract__section-detail-value">{contract.tenantPassportNumber}</div>
                </div>
              </div>
            </div>
            <div className="contract__section">
              <div className="contract__section-title">{t(`Payment`)}</div>
              <div className="row">
                <div className="contract__section-detail">
                  <div className="contract__section-detail-name">{t(`Payment method`)}</div>
                  <div className="contract__section-detail-value">
                    <PaymentMethodPreview method={contract.paymentMethod} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="contract__section-detail">
                  <div className="contract__section-detail-name">{t(`Monthly price`)}</div>
                  <div className="contract__section-detail-value">{contract.monthlyPrice?.toFixed(0)}€</div>
                </div>
                <div className="contract__section-detail">
                  <div className="contract__section-detail-name">{t(`Downpayment`)}</div>
                  <div className="contract__section-detail-value">{contract.downpayment.toFixed(0)}€</div>
                </div>
                {/*
                <div className="contract__section-detail">
                  <div className="contract__section-detail-name">Services price</div>
                  <div className="contract__section-detail-value">100€</div>
                </div>
                */}
              </div>
            </div>
            {showOthers && (
              <>
                <div className="contract__section">
                  <div className="contract__section-title">{t(`Others`)}</div>
                  <div className="row">
                    {!!contract.comment && (
                      <div className="contract__section-detail">
                        <div className="contract__section-detail-name">{t(`Comments`)}</div>
                        <div className="contract__section-detail-value">{contract.comment}</div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        )
      })}
    </>
  )
}

export default Contracts
